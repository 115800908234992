import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Cette page n'existe pas" />
    <div className="max-w-6xl mx-auto">
      <h1 className="mt-20 text-7xl font-archia text-visuall-gris-logo">
        Cette page n'est pas ou plus disponible
      </h1>
      <p className={"text-xl font-display mt-10 text-visuall-gris-logo"}>
        Vous pouvez <Link to={"/"}>revenir à l'accueil</Link>.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
